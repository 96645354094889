import {RUM, sessionStore} from '@eda-restapp/logger'

sessionStore.setData({
  version: VERSION,
  service: 'core'
})

RUM.sendTimeMark(RUM.Counter.Start)

import(/* webpackChunkName: 'bootstrap' */ './bootstrap').finally(() => {
  RUM.time(RUM.Counter.MainJS)
})
